<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  data() {
    return {
      menuData: [
        {
          "text": "Dashboards",
          "icon": "ti-smart-home",
          "active": false,
          "submenu": [
            {
              "text": "Analytics",
              "link": "index.html",
              "icon": "ti-chart-pie-2",
              "active": false
            },
            {
              "text": "CRM",
              "link": "dashboards-crm.html",
              "icon": "ti-3d-cube-sphere",
              "active": false
            },
            {
              "text": "eCommerce",
              "link": "app-ecommerce-dashboard.html",
              "icon": "ti-shopping-cart",
              "active": false
            },
            {
              "text": "Logistics",
              "link": "app-logistics-dashboard.html",
              "icon": "ti-truck",
              "active": false
            },
            {
              "text": "Academy",
              "link": "app-academy-dashboard.html",
              "icon": "ti-book",
              "active": false
            }
          ]
        },
        {
          "text": "Tests",
          "icon": "ti-smart-home",
          "active": false,
          "submenu": [
            {
              "text": "Academy",
              "link": "app-academy-dashboard.html",
              "icon": "ti-book",
              "active": false
            },
            {
              "text": "Academy",
              "link": "app-academy-dashboard.html",
              "icon": "ti-book",
              "active": false
            }
          ]
        },
        {
          "text": "Tests",
          "icon": "ti-smart-home",
          "link": "app-academy-dashboard.html",
          "active": true
        },
        {
          "text": "Dashboards",
          "icon": "ti-smart-home",
          "active": false,
          "submenu": [
            {
              "text": "Analytics",
              "link": "index.html",
              "icon": "ti-chart-pie-2",
              "active": false
            },
            {
              "text": "CRM",
              "link": "dashboards-crm.html",
              "icon": "ti-3d-cube-sphere",
              "active": false
            },
            {
              "text": "eCommerce",
              "link": "app-ecommerce-dashboard.html",
              "icon": "ti-shopping-cart",
              "active": false
            },
            {
              "text": "Logistics",
              "link": "app-logistics-dashboard.html",
              "icon": "ti-truck",
              "active": false
            },
            {
              "text": "Academy",
              "link": "app-academy-dashboard.html",
              "icon": "ti-book",
              "active": false
            }
          ]
        },
        {
          "text": "Tests",
          "icon": "ti-smart-home",
          "active": false,
          "submenu": [
            {
              "text": "Academy",
              "link": "app-academy-dashboard.html",
              "icon": "ti-book",
              "active": false
            },
            {
              "text": "Academy",
              "link": "app-academy-dashboard.html",
              "icon": "ti-book",
              "active": false
            }
          ]
        },
        {
          "text": "Tests",
          "icon": "ti-smart-home",
          "link": "app-academy-dashboard.html",
          "active": true
        },
        {
          "text": "Dashboards",
          "icon": "ti-smart-home",
          "active": false,
          "submenu": [
            {
              "text": "Analytics",
              "link": "index.html",
              "icon": "ti-chart-pie-2",
              "active": false
            },
            {
              "text": "CRM",
              "link": "dashboards-crm.html",
              "icon": "ti-3d-cube-sphere",
              "active": false
            },
            {
              "text": "eCommerce",
              "link": "app-ecommerce-dashboard.html",
              "icon": "ti-shopping-cart",
              "active": false
            },
            {
              "text": "Logistics",
              "link": "app-logistics-dashboard.html",
              "icon": "ti-truck",
              "active": false
            },
            {
              "text": "Academy",
              "link": "app-academy-dashboard.html",
              "icon": "ti-book",
              "active": false
            }
          ]
        },
        {
          "text": "Tests",
          "icon": "ti-smart-home",
          "active": false,
          "submenu": [
            {
              "text": "Academy",
              "link": "app-academy-dashboard.html",
              "icon": "ti-book",
              "active": false
            },
            {
              "text": "Academy",
              "link": "app-academy-dashboard.html",
              "icon": "ti-book",
              "active": false
            }
          ]
        },
        {
          "text": "Tests",
          "icon": "ti-smart-home",
          "link": "app-academy-dashboard.html",
          "active": true
        },
        {
          "text": "Dashboards",
          "icon": "ti-smart-home",
          "active": false,
          "submenu": [
            {
              "text": "Analytics",
              "link": "index.html",
              "icon": "ti-chart-pie-2",
              "active": false
            },
            {
              "text": "CRM",
              "link": "dashboards-crm.html",
              "icon": "ti-3d-cube-sphere",
              "active": false
            },
            {
              "text": "eCommerce",
              "link": "app-ecommerce-dashboard.html",
              "icon": "ti-shopping-cart",
              "active": false
            },
            {
              "text": "Logistics",
              "link": "app-logistics-dashboard.html",
              "icon": "ti-truck",
              "active": false
            },
            {
              "text": "Academy",
              "link": "app-academy-dashboard.html",
              "icon": "ti-book",
              "active": false
            }
          ]
        },
        {
          "text": "Tests",
          "icon": "ti-smart-home",
          "active": false,
          "submenu": [
            {
              "text": "Academy",
              "link": "app-academy-dashboard.html",
              "icon": "ti-book",
              "active": false
            },
            {
              "text": "Academy",
              "link": "app-academy-dashboard.html",
              "icon": "ti-book",
              "active": false
            }
          ]
        },
        {
          "text": "Tests",
          "icon": "ti-smart-home",
          "link": "app-academy-dashboard.html",
          "active": true
        },
        {
          "text": "Dashboards",
          "icon": "ti-smart-home",
          "active": false,
          "submenu": [
            {
              "text": "Analytics",
              "link": "index.html",
              "icon": "ti-chart-pie-2",
              "active": false
            },
            {
              "text": "CRM",
              "link": "dashboards-crm.html",
              "icon": "ti-3d-cube-sphere",
              "active": false
            },
            {
              "text": "eCommerce",
              "link": "app-ecommerce-dashboard.html",
              "icon": "ti-shopping-cart",
              "active": false
            },
            {
              "text": "Logistics",
              "link": "app-logistics-dashboard.html",
              "icon": "ti-truck",
              "active": false
            },
            {
              "text": "Academy",
              "link": "app-academy-dashboard.html",
              "icon": "ti-book",
              "active": false
            }
          ]
        },
        {
          "text": "Tests",
          "icon": "ti-smart-home",
          "active": false,
          "submenu": [
            {
              "text": "Academy",
              "link": "app-academy-dashboard.html",
              "icon": "ti-book",
              "active": false
            },
            {
              "text": "Academy",
              "link": "app-academy-dashboard.html",
              "icon": "ti-book",
              "active": false
            }
          ]
        },
        {
          "text": "Tests",
          "icon": "ti-smart-home",
          "link": "app-academy-dashboard.html",
          "active": true
        },
        {
          "text": "Dashboards",
          "icon": "ti-smart-home",
          "active": false,
          "submenu": [
            {
              "text": "Analytics",
              "link": "index.html",
              "icon": "ti-chart-pie-2",
              "active": false
            },
            {
              "text": "CRM",
              "link": "dashboards-crm.html",
              "icon": "ti-3d-cube-sphere",
              "active": false
            },
            {
              "text": "eCommerce",
              "link": "app-ecommerce-dashboard.html",
              "icon": "ti-shopping-cart",
              "active": false
            },
            {
              "text": "Logistics",
              "link": "app-logistics-dashboard.html",
              "icon": "ti-truck",
              "active": false
            },
            {
              "text": "Academy",
              "link": "app-academy-dashboard.html",
              "icon": "ti-book",
              "active": false
            }
          ]
        },
        {
          "text": "Tests",
          "icon": "ti-smart-home",
          "active": false,
          "submenu": [
            {
              "text": "Academy",
              "link": "app-academy-dashboard.html",
              "icon": "ti-book",
              "active": false
            },
            {
              "text": "Academy",
              "link": "app-academy-dashboard.html",
              "icon": "ti-book",
              "active": false
            }
          ]
        },
        {
          "text": "Tests",
          "icon": "ti-smart-home",
          "link": "app-academy-dashboard.html",
          "active": true
        },
      ],
      meta:{}
    };
  },
  name: 'App',
  computed: {
    ...mapGetters('app', ['filteredMenuItems']),
  },
  components: {
  },
  watch: {
    $route(to, from) {
      // Mettre à jour meta à chaque changement de route
      this.meta = to.meta;
      this.configureDomaines()
    }
  },
  mounted() {
    const appLogo = document.getElementById('appLogo')
    let appLogoLink=`https://api.supervizr.net/logo/${this.$store.state.app.subDomain}.supervizr.png`
    appLogo.src=appLogoLink
    console.log('Voici les elements ' , this.filteredMenuItems,this.menuData)
    const createMenuItem = (item) => {
      let li = `<li class="menu-item ${item.active ? 'active' : ''}">`;
      li += `<a href="${item.link || 'javascript:void(0)'}" class="menu-link ${item.submenu ? 'menu-toggle' : ''}">`;
      li += `<i class="menu-icon tf-icons ti ${item.icon}"></i>`;
      li += `<div data-i18n="${item.text}">${item.text}</div>`;
      li += `</a>`;

      if (item.submenu && item.submenu.length > 0) {
        li += '<ul class="menu-sub">';
        item.submenu.forEach(subItem => {
          li += createMenuItem(subItem);
        });
        li += '</ul>';
      }

      li += '</li>';
      return li;
    };

    const generateMenu = (jsonData) => {
      let html = '<ul class="menu-inner pb-2 pb-xl-0">';
      jsonData.forEach(item => {
        html += createMenuItem(item);
      });
      html += '</ul>';
      return html;
    };

    const menuHtml = generateMenu(this.filteredMenuItems);

    document.getElementById('menu-block').innerHTML = menuHtml;

    // Fonction pour créer et insérer des éléments script
    const loadScript = (src) => {
      const script = document.createElement('script');
      script.src = src;
      script.async = false; // Pour conserver l'ordre de chargement
      document.body.appendChild(script);
    };

    // Charger les scripts nécessaires
    const scripts = [
      "/assets/vendors/libs/jquery/jquery.js",
      "/assets/vendors/libs/popper/popper.js",
      "/assets/vendors/js/bootstrap.js",
      "/assets/vendors/libs/node-waves/node-waves.js",
      "/assets/vendors/libs/perfect-scrollbar/perfect-scrollbar.js",
      "/assets/vendors/libs/hammer/hammer.js",
      "/assets/vendors/libs/i18n/i18n.js",
      "/assets/vendors/libs/typeahead-js/typeahead.js",
      "/assets/vendors/js/menu.js",
      "/assets/vendors/libs/select2/select2.js",
      "/assets/vendors/libs/plyr/plyr.js",
      "/assets/js/main.js"
    ];

    scripts.forEach(src => loadScript(src));
    let deconnectionBouton =document.getElementById("boutonDeconnection");
    deconnectionBouton.addEventListener('click', function() {
      this.setUser({
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        token: '',
        permissions: [],
      })
    });

  },
  beforeMount() {
    this.configureDomaines()
  },
  methods: {
    ...mapActions('app', ['setUser']),
    configureDomaines(){

      let subDomaine=this.$store.state.app.subDomain;
      let pageTitle="";
      if(Object.keys(this.$route).includes("meta") && Object.keys(this.$route.meta).includes("pageTitle") ){
        pageTitle=this.$route.meta.pageTitle
        subDomaine=subDomaine+" / "+pageTitle
        console.log('  Voici les parametres de la route ==>  ',pageTitle,subDomaine)
      }
      const appName = document.getElementById('appName')
      appName.textContent=subDomaine
      // Change page title

      document.title =pageTitle
      let faviconLink=`https://${this.$store.state.app.subDomain}.supervizr.net/logo/${this.$store.state.app.subDomain}.supervizr.ico`

      // Change favicon based on its id
      const faviconElement = document.getElementById('faviconElement') // Replace 'favicon-id' with the actual id
      if (faviconElement) {
        const favicon = document.querySelector('link[rel="icon"]') || document.createElement('link')
        favicon.type = 'image/x-icon'
        favicon.rel = 'icon'
        favicon.href = faviconLink // Replace with your default favicon path
        document.head.appendChild(favicon)
      }
    }
  }
}
</script>

<style>
#appLogo{
 width: 30px;
 height: 25px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;

}
.app-brand-link{
  height: 45px;
}

.modal-dialog .close {
  background-color: #fff;
  border-radius: 0.25rem;
  opacity: 1;
  padding: 0.3757rem;
  box-shadow: 0 0.0625rem 0.375rem 0 rgba(47, 43, 61, 0.1);
  transition: all 0.23s ease 0.1s;
  position: absolute;
  top: -15px;
  right: -15px;
  width: 35px;
  height: 35px;
  border: 0px !important;
}
.modal-xl{
  width: 90% !important;
  max-width: 100% !important;
}
</style>
